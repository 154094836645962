<template>
  <v-row>
    <v-col cols="12" lg="5">
      <validation-observer ref="report" v-slot="{ passes }">
        <v-form @submit.prevent="passes( submit )">
          <v-card flat class="pa-4">
            <v-card-title class="d-block px-0">
              <back-button-component/>
              <div class="d-flex">
                <span class="font-weight-bold d-block">Daily Report</span>
              </div>
            </v-card-title>
            <v-card class="rounded-lg" >
              <v-card-text>
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="mb-4 px-0">
                    <label class="font-weight-bold">1. How do you rate your day?</label>
                  </v-col>
                  <v-col cols="12" class="px-0">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12" class="d-flex justify-start px-0">
                        <div style="max-width: 100%; box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(237, 237, 237, 0.5), inset -5px 5px 10px rgba(237, 237, 237, 0.2), inset 5px -5px 10px rgba(237, 237, 237, 0.2), inset -5px -5px 10px rgba(255, 255, 255, 0.9), inset 5px 5px 13px rgba(237, 237, 237, 0.9)">
                          <template v-for="item of 10">
                            <v-btn :height="$vuetify.breakpoint.lgAndDown ? 36 : 48"
                                   :min-width="$vuetify.breakpoint.lgAndDown ? 36 : 48"
                                   :max-width="$vuetify.breakpoint.lgAndDown ? 36 : 48"
                                   :width="$vuetify.breakpoint.lgAndDown ? 36 : 48"
                                   elevation="0"
                                   :color="form.day_rate === item ? 'primary white--text' : ''"
                                   @click="setRate('day_rate', item)"
                                   style="margin: 1px;" :key="item">
                              <span class="font-weight-thin">{{ item }}</span>
                            </v-btn>
                          </template>
                        </div>
                      </v-col>
                      <v-col cols="10" lg="12" class="d-flex px-0 pr-lg-3 pr-xl-0">
                        <small class="font-weight-thin">Bad day</small>
                        <v-spacer></v-spacer>
                        <small class="font-weight-thin">Good day</small>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <validation-provider name="description" rules="required" v-slot="{ errors }">
                  <v-row>
                    <v-col cols="12">
                      <v-textarea v-model="form.description"
                                  :error-messages="errors"
                                  persistent-placeholder
                                  placeholder="Write down your explaination here..."
                      >
                        <template v-slot:label>
                          <label class="font-weight-bold">Give yourself an explanation:</label>
                        </template>
                      </v-textarea>
                    </v-col>
                  </v-row>
                </validation-provider>
                <v-row>
                  <v-col cols="12" class="mb-4">
                    <label class="font-weight-bold">2. How do you rate your effort today?</label>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12" class="d-flex justify-start px-0">
                        <div style="max-width: 100%; box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(237, 237, 237, 0.5), inset -5px 5px 10px rgba(237, 237, 237, 0.2), inset 5px -5px 10px rgba(237, 237, 237, 0.2), inset -5px -5px 10px rgba(255, 255, 255, 0.9), inset 5px 5px 13px rgba(237, 237, 237, 0.9)">
                          <template v-for="item of 10">
                            <v-btn :height="$vuetify.breakpoint.lgAndDown ? 36 : 48"
                                   :min-width="$vuetify.breakpoint.lgAndDown ? 36 : 48"
                                   :max-width="$vuetify.breakpoint.lgAndDown ? 36 : 48"
                                   :width="$vuetify.breakpoint.lgAndDown ? 36 : 48"
                                   elevation="0"
                                   :color="form.effort_rate === item ? 'primary white--text' : ''"
                                   @click="setRate('effort_rate', item)"
                                   style="margin: 1px;" :key="item">
                              <span class="font-weight-thin">{{ item }}</span>
                            </v-btn>
                          </template>
                        </div>
                      </v-col>
                      <v-col cols="10" lg="12" class="d-flex px-0 pr-lg-3 pr-xl-0">
                        <small class="font-weight-thin">Bad day</small>
                        <v-spacer></v-spacer>
                        <small class="font-weight-thin">Good day</small>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea v-model="form.notice"
                                persistent-placeholder
                                placeholder="Write down your explaination here..."
                    >
                      <template v-slot:label>
                        <label class="font-weight-bold">Explain your effort</label>
                      </template>
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="mb-4">
                    <label class="font-weight-bold">3. Top 5 priorities of the day:</label>
                  </v-col>
                  <template v-for="(i, key) of 5">
                    <v-col cols="12" :key="i">
                      <validation-provider :name="`priority for tommorow #${i}`" rules="required"  v-slot="{ errors }">
                        <v-textarea v-model="form.priorities[key]"
                                    :error-messages="errors"
                                    :disabled="outdated[key] && outdated[key].description === form.priorities[key] && outdated[key].status === 0"
                                    rows="1"
                                    persistent-placeholder
                                    placeholder="Write down your priority here..."
                        >
                          <template v-slot:label>
                            <label class="font-weight-bold">Top #{{ i }} priority for tomorrow</label>
                          </template>
                        </v-textarea>
                      </validation-provider>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
              <v-card-actions class="pb-8 px-4">
                <v-spacer/>
                <v-btn width="89" height="49" rounded
                       class="d-flex align-center font-weight-bold"
                       color="secondary" type="button">
                  <span class="text-capitalize">Cancel</span>
                </v-btn>
                <v-btn width="98" height="49" rounded class="d-flex align-center font-weight-bold"
                       color="primary" type="submit">
                  <span class="text-capitalize">Save</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card>
        </v-form>
      </validation-observer>
    </v-col>
    <v-col cols="12" lg="7">
      <report-data-table-component/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Report",
  data () {
    return {
      form: {
        day_rate: 1,
        effort_rate: 1,
        description: null,
        notice: null,
        priorities: []
      },
      outdated: []
    }
  },
  beforeMount() {
    this.getPriorities()

    console.log(this.outdated)
  },
  methods: {
    setRate (rate, value) {
      this.form[rate] = value
    },
    submit () {
      this.$http.post(`${process.env.VUE_APP_API_URL}/reports`, this.form)
          .then(() => {
            this.$refs.report.reset()
            this.form = this.$worker.resetForm(this.form)
            this.$root.$emit('getReports')
          })
    },
    getPriorities () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/top`)
          .then(response => {
            response.data.outdated.forEach((item) => {
              this.form.priorities.push(item.description)
              this.outdated.push(item)
            })
          })
    }
  }
}
</script>

<style scoped>

</style>